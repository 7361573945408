@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    font-family: 'Montserrat', sans-serif !important;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.sideBar__button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    height: 50px;
}
.sideBar__button span {
    display: none;
}
.sideBar__button:active .sideBar__button span {
    display: flex;
}

.mainModal {
    max-width: 800px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 80px 60px 40px 60px;
    border-radius: 4px;
}

canvas {
    height: 80vh;
    /* width: 100% !important; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiDataGrid-cell,
.MuiDataGrid-columnHeader {
    outline: none !important;
}

.cursor-d {
    cursor: default !important;
}

.react-tabs__tab--selected {
    /* border: 3px solid #0015ffa5 !important; */
    border-radius: 12px !important;
    /* border-bottom: 3px solid #0015ffa5 !important; */
}

:root {
    --tab-1-color: #979cff;
    --tab-2-color: #a8c946;
    --tab-3-color: #ff8768;
}

/* .tab-1.react-tabs__tab--selected > div { */
.tab-1.active > div {
    background-color: var(--tab-1-color) !important;
}

/* .tab-2.react-tabs__tab--selected > div { */
.tab-2.active > div {
    background-color: var(--tab-2-color) !important;
}

/* .tab-3.react-tabs__tab--selected > div { */
.tab-3.active > div {
    background-color: var(--tab-3-color) !important;
}

.tab-1 > div,
.tab-2 > div,
.tab-3 > div {
    background-color: rgba(144, 144, 144, 0.805);
}

.MuiDataGrid-root .MuiDataGrid-columnHeader {
    background: #979cff;
    color: white;
}

.status-select fieldset {
    border: none !important;
}

.status-select {
    font-size: 9.5px !important;
    font-weight: 700 !important;
}

.colorSelect .MuiSvgIcon-root {
    display: none;
}
.colorSelect .MuiSelect-standard {
    padding-right: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorSelect .css-1b98qwf {
    /* height: 15px !important; */
    /* width: 15px !important; */
    margin-right: 0px !important;
}

.colorSelect .MuiBox-root {
    height: 15px !important;
    width: 15px !important;
    margin-right: 0px !important;
}

.colorSelect .css-1t4ek9v::before {
    border: none !important;
}
.colorSelect .css-1t4ek9v::after {
    border: none !important;
}
.css-1t4ek9v:hover:not(.Mui-disabled, .Mui-error)::before {
    border: none !important;
}
.colorSelect
    .css-qdbh77-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
    display: none !important ;
}

.colorSelect .css-qdbh77-MuiInputBase-root-MuiInput-root-MuiSelect-root::after {
    border: none !important;
}

.colorSelect .MuiSelect-select:focus {
    background: none;
}
/* .colorSelect .MuiInputBase-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
} */
.editPen .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 3px !important;
}
.editPen .css-1yxmbwk {
    padding: 3px !important;
}

.color-row-green {
    background-color: #38d46441 !important;
}

.color-row-red {
    background-color: #ff866839 !important;
}

.color-row-blue {
    background-color: #979cff4d !important;
}
